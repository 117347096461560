* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  height: 1280px;
  width: 100%;
  background: url('../../assets/images/header-background-image.jpg') no-repeat;
  background-size: cover;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.navigation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-left: 100px;
  font-family: 'Gagalin-Regular';
  font-size: 33px;
  color: #fff;
}

.navbar {
  display: flex;
  flex-direction: row;
  gap: 70px;
  align-items: center;
  color: #fff;
}

.navList {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
}

.navList li {
  cursor: pointer;
}

.navList li:nth-child(2) {
  margin-right: 70px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.bar {
  height: 3px;
  width: 30px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.4s;
}

.navButton {
  margin-right: 100px;
  padding: 10px 17px ;
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 4px;
}

.headerMainSection {
  position: relative; 
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  margin-right: 0px;
}

.headerLeftSection {
  display: flex;
  flex-direction: column;  
  margin-top: 346px;
}

.headerLeftSection img {
  width: 211px;
  height: 215px;
  margin-left: 148px;
  z-index: 1;
}

.ellipse {
  position: relative;
  width: 68px;
  height: 29px;
  margin-left: 243px;
  margin-top: -20px;
  background: none;
  border: 7px solid #fff;
  border-radius: 50%;
}

.leftSectionTitle {
  font-family: 'Gagalin-Regular';
  font-size: 50px;
  color: #fff;
  margin-left: 175px;
  margin-top: -10px;
}

.leftQuote {
  position: relative;
  top: 120px;
  width: 30px;
}

.leftQuote img {
  width: 30px;
  height: 40px;
}

.rightQuote {
  position: relative;
  bottom: 30px;
  left: 660px;
  width: 30px;
}

.rightQuote img {
  width: 30px;
  height: 40px;
  transform: rotate(120deg);
}

.leftSectionText {
  font-family: 'Gagalin-Regular';
  font-size: 78px;
  color: #fff;
  margin-top: 160px;
  margin-left: 165px;
  transform: rotate(13deg);
}

.illustration {
  position: relative; 
  top: 5px;
  right: 55px;
}

.paperPlane {
  position: relative;
  top: 610px;
  right: 490px;
}

.firstLine {
  position: relative;
  top: 615px;
  right: 365px;
  transform: rotate(160deg);
}

.secondLine {
  position: relative;
  top: 560px;
  right: 250px;
  transform: rotate(-20deg);
}

.thirdLine {
  position: relative;
  top: 560px;
  right: 140px;
  transform: rotate(165deg);
}

.fourthLine {
  position: relative;
  top: 535px;
  right: 40px;
  transform: rotate(5deg);
}

.headerRightSection {
  position: relative;
  right: 130px;
  height: 650px;
}

.rightSectionTitle {
  font-family: 'Gagalin-Regular';
  font-size: 73px;
  color: #fff;
  width: 540px;
  margin-top: 35px;
  margin-right: 30px;
  transform: rotate(-3deg);
}

.rightSectionText {
  font-family: 'Open Sans';
  font-size: 29px;
  color: #fff;
  width: 560px;
  margin-top: 20px;
  transform: rotate(-3deg);
}

.downloadButton {
  display: inline-block;
  position: relative;
  margin-top: 30px;
  margin-left: 55px;
  transform: rotate(-3deg);
}

.shadowBox {
  position: absolute;
  background-color: #000;
  width: 400px;
  height: 54px;
  top: 4px;
  left: 4px;
  border-radius: 5px;
  z-index: 1;
}

.boxButton {
  position: relative;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
  background-color: #fff;
  width: 400px;
  height: 54px;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 2;
  margin-top: 10px;
}

@media (min-width: 1024px) and (max-width: 1570px) {
  .navList li:nth-child(2) {
    margin-right: 0px;
  }

  .headerMainSection {
    position: relative; 
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    margin-right: 0px;
  }
  
  .headerLeftSection {
    display: flex;
    flex-direction: column;  
    margin-top: 346px;
    width: 50%;
  }
  
  .headerLeftSection img {
    width: 211px;
    height: 215px;
    margin-left: 148px;
    z-index: 1;
  }
  
  .ellipse {
    position: relative;
    width: 68px;
    height: 29px;
    margin-left: 243px;
    margin-top: -20px;
    background: none;
    border: 7px solid #fff;
    border-radius: 50%;
  }
  
  .leftSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 40px;
    color: #fff;
    margin-left: 197px;
    margin-top: -10px;
  }
  
  .leftQuote {
    position: relative;
    top: 80px;
    right: 0px;
    width: 30px;
  }
  
  .leftQuote img {
    width: 30px;
    height: 40px;
  }
  
  .rightQuote {
    position: relative;
    bottom: 40px;
    left: 615px;
    width: 30px;
  }
  
  .rightQuote img {
    width: 30px;
    height: 40px;
    transform: rotate(120deg);
  }
  
  .leftSectionText {
    font-family: 'Gagalin-Regular';
    font-size: 73px;
    color: #fff;
    margin-top: 120px;
    margin-left: 165px;
    transform: rotate(13deg);
    width: 610px;
  }
  
  .illustration {
   display: none;
  }
  
  .headerRightSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 50px;
    height: 650px;
    width: 50%;
  }
  
  .rightSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 50px;
    color: #fff;
    width: 400px;
    margin-top: 70px;
    margin-right: 0px;
    transform: rotate(-3deg);
  }
  
  .rightSectionText {
    font-family: 'Open Sans';
    font-size: 25px;
    color: #fff;
    width: 490px;
    margin: 20px 0 30px 0;
    transform: rotate(-3deg);
  }

  .downloadButton {
    position: relative;
    margin-top: 0;
    margin-left: 80px;
    transform: rotate(-3deg);
  }
  
  .shadowBox {
    position: absolute;
    background-color: #000;
    width: 300px;
    height: 54px;
    top: 4px;
    left: 4px;
    border-radius: 5px;
    z-index: 1;
  }
  
  .boxButton {
    position: relative;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: bold;
    background-color: #fff;
    width: 300px;
    height: 54px;
    padding: 5px;
    border-radius: 5px;
    z-index: 2;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .hamburger-menu {
    display: flex;
    margin-right: 100px;
    z-index: 101;
  }

  .bar:nth-child(1) {
    transform: rotate(0);
  }
  
  .bar:nth-child(2) {
    transform: rotate(0);
  }
  
  .bar:nth-child(3) {
    transform: rotate(0);
  }

  .hamburger-menu.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(12px, 4px);
  }
  
  .hamburger-menu.open .bar:nth-child(2) {
    opacity: 0;
    transition: 0s;
  }
  
  .hamburger-menu.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(15px, -6px);
  }

  .navList {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: #333;
    color: #fff;
    width: 100%;
    height: 550px;
    padding: 10px;
  }

  .navList.open {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .navList li:first-child {
    margin-top: 100px;
  }

  .navList li:nth-child(2) {
    margin-right: 0px;
  }
  
  .navButton {
    margin-right: 0px;
  }
  
  .headerMainSection {
    position: relative; 
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }
  
  .headerLeftSection {
    display: flex;
    flex-direction: column;  
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .headerLeftSection img {
    width: 200px;
    height: 216px;
    margin-left: 0px;
    margin-right: 20px;
    z-index: 1;
  }
  
  .ellipse {
    position: relative;
    width: 68px;
    height: 29px;
    margin: -10px 0px 0px 25px;
    background: none;
    border: 7px solid #fff;
    border-radius: 50%;
  }
  
  .leftSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 40px;
    color: #fff;
    margin-left: 0px;
    margin-top: -10px;
  }

  .leftQuote {
    position: relative;
    top: 38px;
    right: 310px;
    width: 30px;
  }
  
  .leftQuote img {
    width: 30px;
    height: 40px;
    transform: rotate(-25deg);
  }
  
  .rightQuote {
    position: relative;
    bottom: 50px;
    left: 312px;
    width: 30px;
  }
  
  .rightQuote img {
    width: 30px;
    height: 40px;
    transform: rotate(120deg);
  }
  
  .leftSectionText {
    font-size: 73px;
    color: #fff;
    margin-top: 70px;
    margin-left: 0px;
    transform: rotate(13deg);
  }
  
  .illustration {
    display: none;
  }
  
  .headerRightSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0px;
    height: 590px;
  }
  
  .rightSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 60px;
    color: #fff;
    width: 500px;
    margin-top: 20px;
    margin-right: 0px;
    transform: rotate(0deg);
  }
  
  .rightSectionText {
    font-family: 'Open Sans';
    font-size: 25px;
    color: #fff;
    width: 500px;
    margin-top: 40px;
    margin-bottom: 30px;
    transform: rotate(0deg);
  }

  .downloadButton {
    position: relative;
    margin: 0;
    transform: rotate(0deg);
    margin-top: 30px;
  }
  
  .shadowBox {
    position: absolute;
    background-color: #000;
    width: 300px;
    height: 54px;
    top: 4px;
    left: 4px;
    border-radius: 5px;
    z-index: 1;
  }
  
  .boxButton {
    position: relative;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    background-color: #fff;
    width: 300px;
    height: 54px;
    padding: 5px;
    border-radius: 5px;
    z-index: 2;
  }
}

@media (max-width: 768px) {  
  .logo {
    margin-left: 30px;
  }  

  .hamburger-menu {
    display: flex;
    margin-right: 20px;
    z-index: 101;
  }

  .bar:nth-child(1) {
    transform: rotate(0);
  }
  
  .bar:nth-child(2) {
    transform: rotate(0);
  }
  
  .bar:nth-child(3) {
    transform: rotate(0);
  }

  .hamburger-menu.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(12px, 4px);
  }
  
  .hamburger-menu.open .bar:nth-child(2) {
    opacity: 0;
    transition: 0s;
  }
  
  .hamburger-menu.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(15px, -6px);
  }

  .navList {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: #333;
    color: #fff;
    width: 100%;
    height: 550px;
    padding: 10px;
  }

  .navList.open {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .navList li:first-child {
    margin-top: 100px;
  }

  .navList li:nth-child(2) {
    margin-right: 0px;
  }
  
  .navButton {
    margin-right: 0px;
  }
  
  .headerMainSection {
    position: relative; 
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }
  
  .headerLeftSection {
    display: flex;
    flex-direction: column;  
    margin-top: 150px;
    justify-content: center;
    align-items: center;
  }
  
  .headerLeftSection img {
    width: 150px;
    height: 168px;
    margin-left: 0px;
    margin-right: 8px;
    z-index: 1;
  }
  
  .ellipse {
    position: relative;
    width: 68px;
    height: 29px;
    margin: -10px 0px 0px 25px;
    background: none;
    border: 7px solid #fff;
    border-radius: 50%;
  }
  
  .leftSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 40px;
    color: #fff;
    margin-left: 20px;
    margin-top: -10px;
  }
  
  .leftQuote {
    position: relative;
    top: 40px;
    right: 130px;
    width: 30px;
  }
  
  .leftQuote img {
    width: 15px;
    height: 20px;
    transform: rotate(-25deg);
  }
  
  .rightQuote {
    position: relative;
    bottom: 20px;
    left: 150px;
    width: 30px;
  }
  
  .rightQuote img {
    width: 15px;
    height: 20px;
    transform: rotate(120deg);
  }
  
  .leftSectionText {
    font-size: 33px;
    color: #fff;
    margin-top: 60px;
    margin-left: 0px;
    transform: rotate(13deg);
  }
  
  .illustration {
    display: none;
  }
  
  .headerRightSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0px;
    height: 650px;
    margin-top: 80px;
  }
  
  .rightSectionTitle {
    font-family: 'Gagalin-Regular';
    font-size: 43px;
    color: #fff;
    width: 300px;
    margin-top: 20px;
    margin-right: 0px;
    transform: rotate(0deg);
  }
  
  .rightSectionText {
    font-family: 'Open Sans';
    font-size: 20px;
    color: #fff;
    width: 300px;
    margin-top: 20px;
    margin-bottom: 30px;
    transform: rotate(0deg);
  }

  .downloadButton {
    position: relative;
    margin: 10px 0 0 0;
    transform: rotate(0deg);
  }
  
  .shadowBox {
    position: absolute;
    background-color: #000;
    width: 200px;
    height: 54px;
    top: 4px;
    left: 4px;
    border-radius: 5px;
    z-index: 1;
  }
  
  .boxButton {
    position: relative;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: bold;
    background-color: #fff;
    width: 200px;
    height: 54px;
    padding: 5px;
    border-radius: 5px;
    z-index: 2;
  }
}

