@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Gagalin-Regular";
  src: local("Gagalin-Regular"),
   url("./assets/font/Gagalin/Gagalin-Regular.ttf") format("truetype");
 }
 @font-face {
  font-family: "PingFangHK";
  src: local("PingFangHK"),
   url("./assets/font/PingFangHK-Regular/PingFangHK-Regular.ttf") format("truetype");
 }
 @font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
   url("./assets/font/Open-Sans/OpenSans-Regular.ttf") format("truetype");
 }
 @font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("./assets/font/Raleway/Raleway-Regular.ttf") format("truetype");
 }
 @font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
   url("./assets/font/OpenSans/OpenSans.ttf") format("truetype");
 }

 