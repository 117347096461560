.footer-container {
    display:flex;
    justify-content: space-around;
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, #72C6EF 0%, #004E8F 100%);
    color: white;
    padding-top:8%;
    font-family: 'SFPROText';
  }
  @font-face {
    font-family: 'SFProText';
    src: url('../../assets/font/SFProText/SFProText.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gagalin-Regular';
    src: url('../../assets/font/Gagalin/Gagalin-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .maptobe-container{
    display: flex;
    flex-direction: column;
    width: 15%;
    gap: 1rem;
    height: 30%;
    text-align: left;
    margin-left:5%;
  }
  .spacer{
    width:20%;
  }
  .maptobe-title{
    width:100%;
    /* height:35px; */
    margin-top: -20px;
    /* margin-bottom:10%; */
    font-size:32px;
    font-family:'Gagalin-Regular';
  }
  .text-container {
    color: #f5fafe;
    font-family: "SFProText";
    font-size: 18px;
    letter-spacing: 0.36px;
    line-height: 25.2px;
    width: 276px;
  }
.copyright{
    margin-top: 5rem;
    margin-bottom: 1rem;
    width: 75%;
    height: 21px;
    font-family: 'SFProText';
    font-size: 14px;
    line-height: 21.08px;
}
.basic-input {
  border: 1px solid #59c3ff; /* Border style and color */
  border-radius: 8px; /* Rounded corners */
  height: 55px; /* Container height */
  width: 240px; /* Slightly wider to ensure responsiveness */
  display: flex; /* Display as a flex container */
  justify-content: center; /* Space between elements */
  align-items: center; /* Center elements vertically */
}

.basic-input .input-text {
  background: transparent; /* Transparent background */
  border: none; /* No input border */
  color: #94d2fa; /* Text color */
  font-family: "OpenSans"; /* Font family */
  font-size: 16px; /* Font size */
  font-weight: 400; /* Font weight */
  width:200px;
  height: 23px; /* Input height */
  letter-spacing: 0.32px; /* Letter spacing */
  line-height: 22.4px; /* Line height */
}

.icon-send-bold {
  width: 21px; 
  height: 21px; 
}

  .input-text:focus {
    outline:none;
  }
  .input-text::placeholder {
    color: #94d2fa; 
    font-size: 16px;
  }
  .basic-input .icon-send-bold {
    height: 21px;
    left: 80px;
    top: 18px;
    width: 23px;
    background-image: url('icon-send-bold.svg'); 

  }
  .basic-input.error {
    border: 1px solid red;
  }
  .error-message{
    font-family:'OpenSans';
    color:red;
    margin-left:10px;
    margin-top:10px;
  }
  .icon-send-bold:hover{
    cursor:pointer;
  }
  .contactus-container{
    width: 15%;
    height:30%;
    text-align:left;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    gap: 15px;
  }
.contact-us{
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: normal;
  width: 100%;
}
.text-wrapper{
    color: #93d2f9;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: 19.6px;
    width: 100%;
    text-align:left;
}
.subscribe{
    width:100%;
    color: #93d2f9;
    font-size: 14px;
}
.uptodate-container{
    width:25%;
    height: 30%;
    text-align:left;
    margin-right:0px;
    margin-bottom: 30px;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 17px;
}
.stay-up-to-date{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.28px;
    line-height: 19.6px;
    font-family: "Open Sans";
}

@media (max-width: 1023px) {
  .footer-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    padding-top: 8%;
  }

  .maptobe-container {
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 0;
  }

  .text-container {
    width: 100%;
    font-size: 16px; 
    line-height: 1.4; 
    letter-spacing: 0; 
    text-align: left;
    margin-bottom: 10px; 
  }

  .contactus-container{
    width: 15%;
    height:30%;
    text-align:left;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    gap: 15px;
  }

  .contact-us{
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: normal;
    width: 100%;
  }

  .text-wrapper{
      color: #93d2f9;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      line-height: 19.6px;
      width: 100%;
      text-align:left;
  }

  .subscribe{
      width:100%;
      color: #93d2f9;
      font-size: 14px;
  }

  .uptodate-container{
      width:30%;
      height: 30%;
      text-align:left;
      margin-right:0px;
      margin-bottom: 30px;
      display:flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 17px;
  }

  .stay-up-to-date{
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.28px;
      line-height: 19.6px;
      font-family: "Open Sans";
  }
  .spacer{
    width: 0;
  }
}

  @media (max-width: 768px) {
   
    .footer-container {
        width:100%;
        height:auto;
        display:flex;
        flex-direction:column;
        justify-content:center;
    } 
    .text-container {
      width:70%;
      font-size: 16px; 
      line-height: 1.4; 
      letter-spacing: 0; 
      text-align:center;
      margin-bottom:10px;
      
    }
    .maptobe-container{
        width:100%;
        height:auto;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        margin-left: 0;
    }
    .contactus-container {
      width: 100%; 
      text-align: center; 
    }
    .uptodate-container{
        width:100%;
        text-align:center;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
    }
    .subscribe{
        width:100%;
        color: #93d2f9;
        height:16px;
        margin-bottom:27px;
    }
    .copyright{
        margin:0;
        text-align: center;
    }
    .contact-us,
    .stay-up-to-date{
        margin-top:15px;
    }
    .maptobe-title{
      width:100%;
      text-align: center;
      margin-bottom:20px;

    }
    .text-wrapper{
      text-align:center;
    }
    
  }